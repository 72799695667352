import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import {
  Form,
  Button,
} from "semantic-ui-react";
import TextInput from "../form/TextInput";



import {
  combineValidators,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";

const validate = combineValidators({
//************user */
  newUserId:isValidPhone({}),
  newEmail:isValidEmail({}),

});


class EditEmailUserId extends Component {
  
  // componentDidMount() {
    
  //   }
  
  // componentWillUnmount(){
  //   this.props.resetFinance()
  // }
  
  onSubmit = async (values) => {

  //************
    try {
     
      const res = await axios.post(`/api/EditEmailUserId`, values);

      if(res.data.msg==='errorUserId'){

        toastr.error('User Id Already Exists Use Another ');
      }
      if(res.data.msg==='errorUserEmail'){

        toastr.error('Email Already Exists Use Another ');
      }
      if(res.data.msg ==='success'){
        toastr.success('Updated Successfully')
      this.props.handleEditModalCloseSubmit()

    }
      }catch (error) {
        
     toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  //**********

}
  backButtonHandler =()=>{
    this.props.history.goBack();
  }

  render() { 
    
    
    const { handleSubmit, pristine, reset, submitting, } = this.props;
    

//****************************

    
      return (
        <div className='marginBottom' >
        <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Edit UserId/Email
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card border">
          <div className=" label" >

            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            type="button"
            onClick={this.props.handleEditModalClose}

          >
            Cancel
          </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />
            
            
            <label >Current UserId</label>
            <Field
              name="oldUserId"
              placeholder="Current UserId"
              type="text"
              disabled
              component={TextInput}
            />
            <label >New UserId</label>
            <Field
              name="newUserId"
              placeholder="New UserId"
              type="text"
              component={TextInput}
            />

{/* <label >Current Phone1</label>
            <Field
              name="oldPhone1"
              placeholder="Current Phone1"
              type="text"
              component={TextInput}
            />
            <label >New Phone1</label>
            <Field
              name="newPhone1"
              placeholder="New Phone1"
              type="text"
              component={TextInput}
            /> */}

<label >Current Email</label>
            <Field
              name="oldEmail"
              placeholder="Current Email"
              type="text"
              disabled
              component={TextInput}
            />
            <label >New Email</label>
            <Field
              name="newEmail"
              placeholder="New Email"
              type="text"
              component={TextInput}
            />
            


            


            
              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting} 
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>

            
</div>
      );
    }

    
}

const mapStateToProps = (state,props) => {

  return {
    state,
    initialValues: {id:props.id,uname:props.uname, oldUserId:props.userId, oldEmail:props.uemail,}

  };
};

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: "EditEmailUserId", // a unique identifier for this form
    //destroyOnUnmount: false
     validate
  })(EditEmailUserId)
);
