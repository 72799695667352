import React, { Component } from "react";
import { connect } from "react-redux";
import { Container,  } from "semantic-ui-react";
import { Switch, Route,Link  } from "react-router-dom";

// import ScrollTopButton from "../scroll/ScrollTopButton";
// import ScrollBottomButton from "../scroll/ScrollBottomButton";
import { showBackButton,logoutUser } from "../../actions/authAction";

import URLCreate from "./URLCreate";
import URLList from "./URLList";
import AppParameterDetail from "./AppParameterDetail";
import AppParameterEdit from "./AppParameterEdit";

//import Layout from "../layout/Layout";


  class AppParameterDashboard extends Component { 
    state = {
      openPop: false,
    };
    componentDidMount() {
      //this.props.getUserForAuth();
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
    }
      }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  //****************************** */
  handleOpenPop = () => {this.setState({ openPop: true }); window.scroll(0,0)}
    handleClosePop = () => this.setState({ openPop: false })
    //********************** */
  render(){
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div style={{marginTop:'50px'}}>

                <div>
                <div className='maindiv'>



           {/*<Layout onLogoutClick={this.onLogoutClick}>*/}
           <Container text>
             <div className='margin-auto' >
               <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

               {/*  */}
            <div className='display-center'>

            <div className="btnnav" >
            <Link to="/appParameter/URLCreate" >
             Create
           </Link>
            </div>
            <div className="btnnav" >
            <Link to="/appParameter/URLList" >
             URL List
           </Link>
            </div>

          </div>
            {/*  */}


            </div>
            </div>
            </Container>

          <Switch>
          <Route exact path="/appParameter/URLCreate" component={URLCreate} />
          <Route exact path="/appParameter/URLList" component={URLList} />
          <Route exact path="/appParameter/AppParameterDetail" component={AppParameterDetail} />
          <Route exact path="/appParameter/AppParameterEdit" component={AppParameterEdit} />

          </Switch>
          {/*</Layout >*/}
          </div>

  {/*<div >
  <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
  </div>*/}

  </div>
      </div>
    );

  return (
    <div>
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth
});

export default connect(
  mapStateToProps,
  {showBackButton, logoutUser }
)(AppParameterDashboard);
