import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
//import 'semantic-ui-css/semantic.min.css';

import store from "../store";
//from devconnector*******

//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";
import { setCurrentUser, logoutUser} from "../actions/authAction";

import AuthDashboard from "./auth/AuthDashboard";
//*********************

import Navbar from "./layout/Navbar";

import Landing from "../components/landing/Home";
import About from "../components/landing/About";
//modal imports
import ModalManager from "./modals/ModalManager";



import ImageDashboard from "./image/ImageDashboard";

//companys imports

import UserDashboard from "./companys/UserDashboard";
import AppParameterDashboard from "./appParameter/AppParameterDashboard";
import EntriesDashboard from "./appParameter/EntriesDashboard";
import UserCreate from "./companys/UserCreate";



// from devconnector******************************************************
// Check for token

  if (localStorage.jwtToken){

  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    //  store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = "/";
  }
}
//***********************************************************


class App extends Component {



  render() {

const commonModuleRouteHandler = ()=>{

    return ( <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/about" component={About} />

      <Route exact path="/register" component={UserCreate} />
      <Route  path="/auth" component={AuthDashboard} />

    </Switch>
  )

}
const commonAuthModuleRouteHandler = ()=>{
  if (this.props.auth.isAuthenticated === true ){
    return ( <Switch>

      <Route  path="/image" component={ImageDashboard} />

      <Route path="/user" component={UserDashboard} />
      <Route path="/appParameter" component={AppParameterDashboard} />
      <Route path="/entries" component={EntriesDashboard} />

    </Switch>
  )
  }
}


//********************************
    return (
      <div>
        <BrowserRouter>
          <div>
            <ModalManager />

            <div>
              <Navbar />

              {commonModuleRouteHandler()}
              {commonAuthModuleRouteHandler()}



            </div>

          </div>
        </BrowserRouter>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
// export default App;

export default connect(
  mapStateToProps,
 null
)(App);
