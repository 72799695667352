import { getMonth, } from 'date-fns'

export const renderMonth =(value)=>{
  if(parseInt(value)===0){return 'January'  }
  if(parseInt(value)===1){return 'February'  }
  if(parseInt(value)===2){return 'March'  }
  if(parseInt(value)===3){return 'April'  }
  if(parseInt(value)===4){return 'May'  }
  if(parseInt(value)===5){return 'June'  }
  if(parseInt(value)===6){return 'July'  }
  if(parseInt(value)===7){return 'August'  }
  if(parseInt(value)===8){return 'September'  }
  if(parseInt(value)===9){return 'October'  }
  if(parseInt(value)===10){return 'November'  }
  if(parseInt(value)===11){return 'December'  }
}
export const renderPreviousMonth =(value)=>{
  if(parseInt(value)===1){return 'January'  }
  if(parseInt(value)===2){return 'February'  }
  if(parseInt(value)===3){return 'March'  }
  if(parseInt(value)===4){return 'April'  }
  if(parseInt(value)===5){return 'May'  }
  if(parseInt(value)===6){return 'June'  }
  if(parseInt(value)===7){return 'July'  }
  if(parseInt(value)===8){return 'August'  }
  if(parseInt(value)===9){return 'September'  }
  if(parseInt(value)===10){return 'October'  }
  if(parseInt(value)===11){return 'November'  }
  if(parseInt(value)===0){return 'December'  }
}

export const monthOptionO = [
  renderPreviousMonth(getMonth(new Date())),
  renderMonth(getMonth(new Date()))
  ]

export const monthOption = () => {
  return monthOptionO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};