import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchAppParameter,updateAppParameter} from "../../actions/optionAction";
import { withRouter } from "react-router-dom";

import {  Form, Button,   } from "semantic-ui-react";
import SelectInput from "../form/SelectInput";

import TextInput from "../form/TextInput";




class AppParameterEdit extends Component {
  componentDidMount() {
    //const { id } = this.props.match.params;
    this.props.fetchAppParameter();
  }

  onSubmit = values => {
    //const { id } = this.props.match.params;
    this.props.updateAppParameter(values, this.props.history);
    //this.props.history.goBack()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
    const url = ()=>{
      return this.props.parameter !== undefined &&
      this.props.parameter !== null &&
      this.props.parameter.allURL.length >0
          && this.props.parameter.allURL.map( (value) =>({
        key:value,
        text:value,
        value:value
      })


      )
    }
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>
      {/**/}
      <div className='margin-auto-H' >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
 {/*  */}
 Edit URL
{/*  */}
</div>
</div>
{/**/}

        <div className="card border ">
          <div className=" label" >

            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

             <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <br /><br />
          <label >currentURL</label>
              <Field
                name="currentURL"
                type="text"
                options={url()}
                search={true}
                component={SelectInput}
                placeholder="currentURL"
              />
              <label >imageInfoURL</label>
              <Field
                name="imageInfoURL"
                type="text"
                options={url()}
                search={true}
                component={SelectInput}
                placeholder="imageInfoURL"
              />
              <label >imageProfileURL</label>
                <Field
                  name="imageProfileURL"
                  type="text"
                  options={url()}
                  search={true}
                  component={SelectInput}
                  placeholder="imageProfileURL"
                />
    
                <label >limitCompany</label>
                <Field
                  name="limitCompany"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitCompany"
                />
                <label >limitBorrowLend</label>
                <Field
                  name="limitBorrowLend"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitBorrowLend"
                />
                <label >limitLocation</label>
                <Field
                  name="limitLocation"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitLocation"
                />
                <label >limitTask</label>
                <Field
                  name="limitTask"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitTask"
                />
                <label >limitRP</label>
                <Field
                  name="limitRP"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitRP"
                />
                <label >limitFinance</label>
                <Field
                  name="limitFinance"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitFinance"
                />
                <label >limitContact</label>
                <Field
                  name="limitContact"
                  type="number"
                  options={url()}
                  search={true}
                  component={TextInput}
                  placeholder="limitContact"
                />
                


              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>
    );

  }
}

export default connect(
  state => {

    return {parameter: state.commons.parameter, initialValues: state.commons.parameter, }; // pull initial values from common reducer
  },
  {fetchAppParameter,updateAppParameter}
)(
  reduxForm({
    form: "AppParameterEdit" // a unique identifier for this form
    // validate
  })(withRouter(AppParameterEdit))
);
