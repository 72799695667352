import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../actions/modalAction";
import {  Button,  Header, } from "semantic-ui-react";


const TermsAndConditions = props => {
  
  return (
    <div style={{maxWidth:'700px',margin:'auto'}} >
        <div  className ='border-bottom-form'  >
        <Header
                color="blue"
               content="Terms & Conditions "
              />
        </div>
        
        <p>
                These Terms of Use ("Agreement") form a legal agreement
                between Bharat ITSYS. having its offices at xxxxxxxxxx
                xxxxxxxxxxxxxxxx xxxxxxxxxx xxxxxxxxxxxxxxx xxxxxxx
                (“BITS”) and You. This Agreement will govern Your access
                 to and use of the Services offered by BITS via this
                 website, applications (hereinafter called as “apps”)
                 and services (collectively, “BITS Products”). If You are
                  entering into this Agreement on behalf of a company,
                  organization or any legal entity (an “Entity”), You are
                  agreeing to this Agreement for that Entity and
                  representing to BITS that You have the authority to
                  bind such Entity to this Agreement.
                  </p>
                  <p>If You do not agree to this Agreement, You must not
                  sign up for an account and shall not make use of any of the
                  Services available through this Site. By accessing or using the
                  Services, You acknowledge that you have read, understood and
                  agree to be bound by the terms of this Agreement. If you do not
                  agree, please do not access or use the Services.

                </p>
                <h6>Definition
                </h6>
                <p>- “Confidential Information” shall mean any information that is marked or otherwise designated as “Confidential” or is clearly by its nature confidential and is disclosed to You by BITS in connection with this Agreement.
                </p>
                <p>- “Data” shall mean the content created, shared or stored by You with BITS using the Services.
                </p>
                <p>- “Service(s)” shall mean the IoT software solution and its functionalities as developed, introduced and described in this Site or Mobile App by BITS from time to time.
                </p>
                <p>- “Site” shall mean the website from which You will sign up/ sign in to use the Services.
                </p>
                <p>- “App” shall mean the mobile application from which You will sign up/ sign in to use the Services.
                </p>
                <p>- “Third Party” or “Third Parties” shall mean the website from which You will sign up/ sign in to use the Services.
                </p>
                <p>- "You” or “Your”shall mean a person, whether natural or legal, acting in the course of a business, and who agrees to be bound by the terms contained in this Agreement.
                </p>
                <h6>Description
                </h6>
                <p>BITS offers REA-CONNECT a software as a service (SaaS) is  IoT
                software solutions. REA-CONNECT is a complete resource management
                 | client management | office management | Networking software
                 for real estate individual and enterprise level brokers and
                 consultants. It will make their business process simple and
                 smooth and help them to provide Real estate Services to their
                 clients more efficiently and effectively than ever before
                 </p>
                 <h6>Modifications to the Terms
                 </h6>
                 <p>BITS may modify the terms of this Agreement from time to time.
                  In case of any significant changes which may affect Your
                  rights under this Agreement, You will be provided with a
                  advance notice of such changes by email to the email address
                  associated with Your account. You agree that such notification
                  shall constitute an adequate notice in this regard.
                  </p>
                  <p>All new features that augment or enhance the current Service,
                   including the release of new tools and resources, shall be
                   subject to the Terms of Service. Continued use of the Service
                   after any such changes shall constitute your consent to said
                   changes.

                   </p>
                   <h6>License & Restrictions
                   </h6>
                   <p>BITS grants to You a limited, non-exclusive and non-assignable
                   license to use the Services, for Your internal business purposes,
                    in accordance with the terms of this Agreement. If You wish to
                    use the Services in any other manner, say for example to
                    provide managed services, You should obtain express written
                    consent from BITS
                    </p>
                    <p>In addition to the other terms & conditions of this
                    Agreement, You agree to:
                    </p>
                    <p>Do not resell, transfer, lease, sub-license, modify ,duplicate, reproduce, or exploit any part of the Service without the explicit written permission of BITS.

                    </p>
                    <p>Do not permit any third parties to use the Services except where such access is required for You to effectively use a communication or collaboration mechanism that forms part of the Services;
                    </p>
                    <p>Do not interfere with the Services or disrupt the integrity of any data contained therein;
                    </p>
                    <p>Do not violate any applicable law. You are responsible for your actions and the consequences of your actions.
                    </p>
                    <p>Do not use the Services in violation of this Agreement unless such use is expressly permitted by BITS.

                    </p>
                    <p>BITS may communicate with you via email regarding your account, updates, news, and other issues related to your account. You automatically get subscribed to our mailing lists and newsletters. You can choose to opt out from receiving emails.
                    </p>
                    <p>You are responsible for all content posted and activity that occurs under your account (even if the content is user generated) - be it in the live chat, help desk, phone or social.
                    </p>
                    <p>We have a soft limit on all our plans and should we believe that you are abusing the service, we may temporarily suspend your account and will seek your cooperation in this regard.
                    </p>
                    <p>We reserve the rights to accept or refuse to any potential client. We have the sole discretion on whether to allow you to register, renew, change plan, or use our services.
                    </p>
                    <p>Your use of the Service, including any content, information, or functionality contained within it, is provided "as is" and "as available" with no representations or warranties of any kind, either expressed or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. You assume total responsibility and risk for your use of this Service.
                    </p>
                    <p>You understand that the Service can be used for transmission of your Content, and that during processing your Content may be transferred unencrypted over the internet.
                    </p>
                    <p>You understand that BITS uses third party vendors and hosting partners to provide necessary hardware, software, information, networking, storage, and related technology to run the service.
                    </p>
                    <p>You may not use the service to store, host, or send unsolicited email (spam), chats or SMS messages. BITS products and services are Anti-Spam compliant and does not authorize or permit spam to be sent out via the automation service by you. If there is evidence of spam, your services might be suspended without notice. Accidental spam must immediately be reported to BITS to prevent suspension.
                    </p>
                    <p>You may not use the service to transmit any viruses, worms, malicious content or engaging in hate speech, advocating violence against others.
                    </p>
                    <p>You may not engage in activity that is false or misleading (e.g. attempting to ask for money under false pretenses or impersonating someone else).
                    </p>
                    <p>You may not infringe upon the legal rights of others (e.g. unauthorized sharing of copyrighted music, photographs and other content).
                    </p>
                    <p>You may not engage in activity that violates the privacy of others.
                    </p>
                    <p> You may not circumvent, disable or otherwise interfere with the security related features of the Software, Products or B2BBricks Websites or features that prevent or restrict the use of any content.

                    </p>
                    <p>BITS makes no warranties regarding (i) your ability to use the Service, (ii) your satisfaction with the Service, (iii) that the Service will be available at all times, uninterrupted, and error-free (iv), the accuracy of mathematical calculations performed by the Service, and (v) that bugs or errors in the Service will be corrected.
                    </p>
                    <p>BITS, its affiliates and its sponsors are neither responsible nor liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, or other damages arising out of or relating in any way to your use of the Service. Your sole remedy for dissatisfaction with the Service is to stop using the Service.
                    </p>
                    <p>If any provision of the Terms of Service is held invalid or otherwise unenforceable, the enforceability of the remaining provisions shall not be impaired thereby.
                    </p>
                    <p>BITS may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene, or otherwise objectionable or violates any party's intellectual property or these Terms of Service.
                    </p>
                    <p>BITS are constantly improving the Software and Products and may change them at any time. Additionally BITS may stop providing the Software and Products (or portions thereof) including (without limitation) that it is no longer feasible for us to provide it, the technology advances, customer feedback indicates a change is needed, external issues arise that make it imprudent or impractical to continue, or any reason, you may need to use an upgraded version of the Software. If you do not agree with any changes to Software and Products you may terminate your relationship with BITS. You may need to upgrade to a new version in order to enjoy the benefit of certain Products. In addition, you acknowledge and agree that certain Products may be subject to usage limits or geographical restrictions, which may vary from time to time.

                    </p>
                    <p>The Terms of Service sets forth the entire understanding between you and BITS as to the Service, and supersedes any prior agreements between you and BITS (including, but not limited to, prior versions of the Terms of Service).
                    </p>
                    <h6>Your obligations
                    </h6>
                    <p>You need to sign up for a user account by providing your
                name, official email address and/ or official phone number
                to use the Services. You agree to: a) provide Facilio with
                true, accurate, current and complete information about You
                while signing up for the Services; and b) maintain and
                promptly update this information during Your course of use
                of the Services.
                </p>
                <p>You are responsible for keeping and maintaining Your
                login credentials strictly confidential and maintaining the
                security of your account and password. BITS shall not be
                held responsible for any unauthorized access to Your account
                 arising from Your failure to keep Your login credentials safe
                 and secure and will not be liable for any loss or damage from
                 your failure to comply with this security obligation.
                </p>
                <h6>Fees and Payment Terms
                </h6>
                <p>You agree to pay to BITS  any fees with  all applicable taxes
                and expenses determined by BITS at the time of signing up for the
                 Services Or after the free trial period ends if you want to
                 continues to use our services
                 </p>
                 <p>BITS reserve the right to modify the fees for the Services
                 periodically. Prices of all Services, including but not limited
                 to subscription plan fees of the Service, are subject to change
                 without notice from us. BITS shall not be liable to you or to
                 any third party for any modification, price change.
                 </p>
                 <h6>Proprietary Rights
                 </h6>
                 <p>All proprietary rights, including intellectual property rights in the Services and any documentation related thereto are the exclusive property of Facilio. Facilio does not grant to You or anyone acting for You any right or title to such proprietary rights, or the permission to use such proprietary rights, except for the limited purposes expressly provided for in this Agreement. No part of this Agreement shall be construed as transferring any element of such proprietary right to You.
                 </p>
                 <h6>Data Ownership
                 </h6>
                 <p>You grant to BITS an irrevocable, perpetual, non-exclusive, transferable, and a worldwide license to copy, store, use, retain, transmit, scan, reformat, edit, translate, excerpt, adapt and distribute the content created by You, solely for the purpose of providing the Services to you. Except for the right expressly granted herein, you retain entire ownership on the data created or stored by You using BITS Services.
                 </p>
                 <h6>  Confidentiality
                 </h6>
                 <p>You agree (i) to hold BITS’s Confidential Information in
                 strict confidence, using at least the same degree of care with
                 which You protect Your own confidential or proprietary
                 information; (ii) not to use such Confidential Information for
                 any purpose outside the scope of this Agreement; and (iii) not
                 to disclose such Confidential Information to third parties not a
                 uthorized by BITS to receive such Confidential Information.
                 </p>
                 <p>These obligations shall survive for a period of five (5)
                 years from the date of termination of this Agreement.
                 </p>
                 <h6>Indemnity
                 </h6>
                 <p>You agree to indemnify BITS from and against all claims,
                 actions and proceedings arising out of or resulting from and
                 against any losses, damages, fines and expenses (including
                   attorney's fees and costs) arising out of or relating to any
                   claims that You have used the Services (i) in violation of a
                   third party's rights, (ii) in violation of any law, (iii) in
                   violations of any provisions of this Agreement, or (iv) any
                   other claim related to your use of the Services, except where
                   such use is authorized by BITS. This indemnification includes,
                    without limitation, liability relating to copyright
                    infringement, defamation, invasion of privacy, trade mark
                    infringement and breach of this Agreement.

                    </p>
                    <h6>Warranty & Disclaimer
                    </h6>
                    <p>  BITS warrants that the Services will perform in
                    accordance with the documentation provided for this Service.
                     Except for the warranties explicitly provided under this
                      Agreement, BITS shall offer the Services on an "as is"
                      basis without warranties of any kind including but not
                      limited to the implied warranties of merchantability,
                      fitness for a particular purpose, or non-infringement of
                      intellectual property.
                    </p>
                    <h6>Limitation of Liability
                    </h6>
                    <p>In no event BITS including affiliates and their respective
                    owners, directors, officers, agents, shareholders and partners
                     shall be liable for any loss or damage whatsoever including
                     without limitation direct, indirect, punitive, incidental and
                      consequential damages, lost profits, or damages resulting from
                       the use or inability to use the Website whether based on
                       warranty, contract, tort or in any other means or action.
                BITS including affiliates and their respective owners, directors,
                 officers, agents, shareholders and partners aggregate and total
                  liability (whether in contract, tort, negligence, under an
                    indemnity, or by statute or otherwise) shall not exceed INR
                     5,000/- (Rupees Five Thousand) or the last payment made by
                     the user; whichever is lesser, subject to applicable law and
                      awarded by the competent court.
                      </p>
                      <h6>  No waiver for breaches
                      </h6>
                      <p>If we do not act in relation to a breach of the
                      Agreement by you, that should not be construed as a
                      waiver of any of our rights to act in relation to that
                      breach or any later breach by you.

                      </p>
                      <h6>Arbitration
                      </h6>
                      <p>Any controversy or claim arising out of or relating to
                      this Agreement shall be settled by binding arbitration in
                      accordance with the rules of the Judicial Arbitration and
                       Mediation Services. Any such controversy or claim shall
                       be arbitrated on an individual basis, and shall not be
                       consolidated in any arbitration with any claim or
                       controversy of any other party. The decision of the
                       arbitrator shall be final and un-appealable. The
                       arbitration shall be conducted in the NCT of DELHI,
                       India. If a dispute arises regarding these terms of use,
                        the laws of the Republic of India will apply and shall
                        be subject to the exclusive jurisdiction of courts in
                        NCT of DELHI, India.

                        </p>
                        <h6>Termination
                        </h6>
                        <p>You may terminate Your account, with or without cause,
                         by requesting closure of your User Account, ceasing to
                         use the Software, Products and/or REA-CONNECT Websites
                          and mobile app. You are solely responsible for properly
                           canceling your account. Please ensure that the
                           cancelation requests are made only through the email
                           associated with Your account.
                           </p>
                           <p>BITS does not offer any refund amount or payment in any circumstances
                           </p>
                           <p>BITS may terminate Your account  if You fail to make payment for the Services or if You are in breach of any  term in this Agreement and fail to cure within given time.
                           </p>
                           <p>BITS may immediately terminate Your account if You violate any applicable law or use the Services in violation of this Agreement unless such use is expressly permitted by BITS
                           </p>
                           <p>  BITS, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service, or any other BITS service, for any reason, at any time. Such termination of the Service will result in the deactivation or deletion of your Account or access to your Account, and the forfeiture and relinquishment of all content in your Account. BITS reserve the right to refuse service to anyone for any reason at any time.

                           </p>
                           <p>Once your account is canceled or terminated, all your account information will be permanently deleted.
                           </p>
                           <h6>  End of Terms
                           </h6>
                           <p>Please write to us at legal@bits.com if you have questions or clarifications regarding this Agreement.
                           </p>


                           <Button
              color="color5"

              type="button"
              onClick={()=>props.closeModal()}

            >
             Got it
            </Button>


            </div>
  );
};

export default connect(
 null,
   {closeModal}
)(TermsAndConditions)

