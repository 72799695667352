import React from 'react'
const Checkbox = ({ label,onChange, checked, ...props }) => {
  
  return (
    <span className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={checked ? "checked" : ""}
          {...props}
        />
        <span>{label}</span>
      </label>
      {/* <p>{checked ? "Selected" : "Unchecked"}</p> */}
    </span>
  );
};
export default Checkbox;
