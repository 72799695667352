import React, {Component} from 'react';
import ReactDOM from "react-dom";
//import { Link } from 'react-router-dom';
//import './styleABOUT.css';
//import Navbar from "./layout/NavbarLanding";

class Landing extends Component {
  
  componentDidMount() {
    window.addEventListener('load', this.run);
    window.addEventListener('resize', this.run);
    window.addEventListener('scroll', this.run);

  }

  componentWillUnmount() {
    window.removeEventListener('load', this.run);
    window.removeEventListener('resize', this.run);
    window.removeEventListener('scroll', this.run);
  }
  isInViewport = el => {
    const node = ReactDOM.findDOMNode(this);

    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || node.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || node.documentElement.clientWidth)
    );
  };
  run = () =>{
    const node = ReactDOM.findDOMNode(this);
    const items = node.querySelectorAll('#timeline li');
    return items.forEach(item => {
      if (this.isInViewport(item)) {
        item.classList.add('show');
      }
    });
  }

render() {
  return (
    <div >

    {/* Navbar */}
    <nav id="navbar" className="mastheadNAV">
        <h1 className="logo">
          <span className="text-primary">
            <i className="fas fa-book-open"></i> BHARAT</span> ITSYS
        </h1>
        <ul>
          <li><a href="#navbar">Home</a></li>
    	  <li>|</li>
          <li><a href="#product">Products</a></li>

          <li>|</li>

          <li><a href="https://goo.gl/maps/KUc9cRaPepg9p5xU7/">Who</a></li>
          <li>|</li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
{/* Header: Showcase */}
<section id="showcase" className="masthead ">
    <div className="showcase-content" >
    <div >
      <h1 style={{fontSize:'250%',textAlign:'center',}} className="py-0">
      Run your Life and Business as you Reinvent it with our Products,
      Solutions, and Services
      </h1>
      </div>
      <div>
      <h3  style={{fontSize:'150%',color:'white',textAlign:'center',}} className="py-0">
      The Company of the People,
      by the People,
      Transforming Life of the People.
      </h3>
      </div>


    </div>
  </section>

<div id="maincontainerP">
{/* Section: What We Do */}
<section id="what" className="bg-light py-1">
<div className="containerP">
  <h1 className="head-main">
  Who We Are
  </h1>
      <div>
        <h3>BHARAT ITSYS is a Technology Company that Designs,
        Develops, Sells and Supports IT Products and Services.

         </h3>
         </div>

</div>
</section>

<div className="row">
<div class="side">
    <h1>~ Our Mission ~</h1>
</div>
<div class="main">
    <h3 >Our mission is to make professional and personal life of every
     person much simpler, more pleasant and more productive and empower
     every person and every organization to achieve more through our
     innovative hardware, software, and services.</h3>
</div>
</div>

<div className="row">
<div class="main">
    <h3>Our Vision is to provide the best technology,
    great products and unmatched services to the world.</h3>
</div>

<div class="side">
    <h1>~ Our Vision ~</h1>
</div>
</div>
<div className="row">
<div class="side">
    <h1>~ Our Products ~</h1>
</div>
<div  id="product" class="main">
<h3>
REA-CONNECT is a software as a service (SaaS).
REA-CONNECT is a complete resource management | client
management | office management | Networking software for real
estate individual and enterprise level brokers and consultants.
 It will make their business process simple and smooth and
 help them to provide Real estate Services to their clients
 more efficiently and effectively than ever before
</h3>
<h3>
It enables the user to take care of every aspect of business. In
a single place, you can manage all your Leads, Properties, Enquiries,
Contacts, Finances, Agreements, Important Notes, Site-visits, Tasks,
Meetings, Follow-ups, and more.
</h3>
<h3>
FINDREALESTATE As there are no fake listings or fake data
FindRealEstate.com is India's only Property portal to provide most
genuine and accurate residential and commercial real estate information
and therefore most trusted Property portal to find a home or commercial
space in India. It provides a platform for property buyers and sellers
to find properties of interest and information on the real estate space
in a transparent and unambiguous manner.
</h3>
<h3>
FindRealEstate.com has all the required and most advance features such
as Users can Post their property or requirement. Advance and easy search
options to filter, shortlist, and finally find the exact property user
are looking for. Property or Project have all details like photos,
amenities, nearby amenities such as ATM, Park, bus , school, restaurant
etc., floor plans, specifications and many more

</h3>
</div>
</div>

<div

className="row">
<div class="main">
    <h3>
    Behind every great human achievement, there is a team.
    We’ve assembled a great team of talented, innovative, highly
    motivated and incredibly passionate people with a deep background
    in information and technology and having decades of experience
    together.
    </h3>
    <h3>
    Do it right the first time, do it fast, and have fun while you
    do it. Our wonderful and enthusiastic team work like that and
    committed towards bringing best technology, great products and
    unmatched services to people.
</h3>
</div>

<div class="side">
    <h1>~ Our Team ~</h1>
</div>
</div>

  </div>

{/* Footer */}
<footer id="main-footer" className="bg-dark text-center py-1">
<div className="containerP" >
<div className="row" style={{border:'none'}}>
<div className="" style={{ display: 'flex',flex: '20%',padding: 20}}>
<h3 className="logo">
  <span className="text-primary">
    <i className="fas fa-book-open"></i> BHARAT</span> ITSYS
</h3>
</div>
<div className="main" style={{ flex: '80%'}}>
<nav id="navbarfoot" className="">

<ul>
  <li><a href="#navbar">Home</a></li>
<li>|</li>
  <li><a href="#product">Products</a></li>

  <li>|</li>

  <li><a href="#what">Who</a></li>
  <li>|</li>
  <li><a href="#contact">Contact</a></li>
</ul>
      <p style={{ textAlign:'left'}}> © Copyright 2019 BHARAT ITSYS, All Rights Reserved</p>
  </nav>

</div>

</div>
</div>
</footer>
    </div>
  );
}
}

export default Landing;
