import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getUserForAuth} from "../../actions/authAction";
import { fetchAppParameter } from "../../actions/optionAction";
import {
  // renderMonth,
  //renderPreviousMonth,
  monthOptionO
} from "../functions/Functions";

import {
  //Input,
  Form,
  Container, 
  Segment,
  Button,
  Divider,
  //Confirm,
} from "semantic-ui-react"; 



class TotalEntriesSingleCompany extends Component {
  state = { 
    finance : 0,
    month : '',
   };

  componentDidMount() {
    this.props.getUserForAuth()
    this.props.fetchAppParameter()
  }
  
  values=()=>{
    return  {
      id : this.props.match.params.id,
      month:this.state.month ,
    };
  }

  monthOption = () => {
    return monthOptionO.map(value => <option key={value} value={value}>{value}</option>);
  };
  //@@@@
  
  
   //** */ 
   totalEntriesFinance = async () => {
    
try{
  if(this.state.month){
  const res = await axios.post(`/api/TotalEntriesSingleCompanyFinance`, this.values());
  
    this.setState({finance:res.data.financeEntries })
}else{
  toastr.error( "Select Month");
}
  
}catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again");
 }
    
    
  };

   

//**********************************
  //@@@@
  

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  };

  renderSearchForm = ()=>
(  <Segment attached>
  <div>
    <Form onSubmit={this.onSubmit}>

  
      <div >

        
      <Form.Field >
      <label >Select Month</label>
      <select
            name="month"
            value={this.state.month}
            onChange={this.handleChange}
          >
            <option value="">Select Month</option>
            {this.monthOption()}
            {/* {options()} */}
          </select>
        
      </Form.Field>
      
        
      </div>
    </Form>

    </div>
  </Segment>

)


  render() {
    
    if (
      this.props.userA !== null &&
      (this.props.userA.financeRead === "Yes" ||this.props.userA.userType === "guest"||
        this.props.userA.userType === "admin")
    ) {
    return (
      <Container text>
      

        {/**/}
        <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Total Entries Of Single Users
       {/*  */}
       </div>
       </div>
       {/**/}
       {this.renderSearchForm()}
       {/*  */}
       <Segment >
       
        <Divider fitted />

        <div className="disflex-paginator" >
                
        <div className='width200 bolder' >
        {this.state.finance}
        </div>
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="blue" onClick={this.totalEntriesFinance }>
          Total Entries Finance
        </Button>
      
        </div>
        
        {/*  */}
        </Segment>
        {/*  */}
         
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View Total Entries </h2>
        </div>
      </Segment>
    </Container>
  );
  }
  }



const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
    userA: state.auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {getUserForAuth,fetchAppParameter}
)(TotalEntriesSingleCompany);

