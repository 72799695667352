import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getUserForAuth} from "../../actions/authAction";
import { fetchAppParameter } from "../../actions/optionAction";


import {
  Input,
  Form,
  Container, 
  Segment,
  Button,
  Divider,
  Confirm,
} from "semantic-ui-react"; 



class DeleteEntries extends Component {
  state = { 
    url: "",
    limit:0,
    //dateL: "",
    dateH: "",
    module:'',
    finYear : '',
   };

  componentDidMount() {
    this.props.getUserForAuth()
    this.props.fetchAppParameter()
  }
  
  values=()=>{
    return  {
      url:this.state.url ,
      limit:this.state.limit ,
      dateH:this.state.dateH ,
      finYear:this.state.finYear ,
    };
  }

  fyOption = () => {
    return this.props.parameter !== undefined && 
    this.props.parameter !== null && 
    this.props.parameter.allFY.map(value => <option key={value} value={value}>{value}</option>);
  };
  //@@@@
  // deleteNote = async () => {
    
  //   try{

  //     const res = await axios.post(`/api/DeleteNote`, this.values() );
  //     if(res.data.msg === 'success'){
  //       toastr.success('Deleted Successfully')
  //     }
  //   }catch (error) {
  //     toastr.error("Oops", "Something went wrong.Please Try Again");
  //    }
    
    
  // };
  // //*** */
  // deleteContact = async () => {
    
  //   try{
  //     const res = await axios.post(`/api/DeleteContact`, this.values() );
  //     if(res.data.msg === 'success'){
  //       toastr.success('Deleted Successfully')
  //     }
  
  //   }catch (error) {
  //     toastr.error("Oops", "Something went wrong.Please Try Again");
  //    }
    
    
  // };
  //** */ 
  deleteReceivablePayable = async () => {
    
try{
  if(this.state.url ){
  const res = await axios.post(`/api/DeleteReceivablePayable`, this.values() );
  if(res.data.msg === 'success'){
    toastr.success('Deleted Successfully')
  }
}else{
  toastr.error( "Select url");
} 
}catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again");
 }
    
    
  };

   //** */
   deleteTask = async () => {
    
try{
  const res = await axios.post(`/api/DeleteTask`, this.values() );
  if(res.data.msg === 'success'){
    toastr.success('Deleted Successfully')
  }
  
}catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again");
 }
    
    
  };

   //** */ 
//    deleteLent = async () => {
    
// try{
//   if(this.state.url ){
//   const res = await axios.post(`/api/DeleteLent`, this.values() );
//   if(res.data.msg === 'success'){
//     toastr.success('Deleted Successfully')
//   }
// }else{
//   toastr.error( "Select url");
// }
// }catch (error) {
//   toastr.error("Oops", "Something went wrong.Please Try Again");
//  }
    
    
//   };

//    //** */ 
//    deleteBorrowed = async () => {
    
// try{
//   if(this.state.url ){
//   const res = await axios.post(`/api/DeleteBorrowed`, this.values() );
//   if(res.data.msg === 'success'){
//     toastr.success('Deleted Successfully')
//   }
// }else{
//   toastr.error( "Select url");
// } 
// }catch (error) {
//   toastr.error("Oops", "Something went wrong.Please Try Again");
//  }
    
    
//   };

//    //** */ 
//    deleteItemLocation = async () => {
    
// try{
//   if(this.state.url ){
//   const res = await axios.post(`/api/DeleteItemLocation`, this.values() );
//   if(res.data.msg === 'success'){
//     toastr.success('Deleted Successfully')
//   }
// }else{
//   toastr.error( "Select url");
// }
// }catch (error) {
//   toastr.error("Oops", "Something went wrong.Please Try Again");
//  }
    
    
//   };

//    //** */ 
//    deleteFinance = async () => {
    
// try{
//   if(this.state.finYear && this.state.url ){
//   const res = await axios.post(`/api/DeleteFinance`, this.values() );
//   if(res.data.msg === 'success'){
//     toastr.success('Deleted Successfully')
//   }
// }else{
//   toastr.error( "Select Financial Year and url");
// }
  
// }catch (error) {
//   toastr.error("Oops", "Something went wrong.Please Try Again");
//  }
    
    
//   };

//    //** */
//    deleteAccountName = async () => {
    
// try{
//   const res = await axios.post(`/api/DeleteAccountName`, this.values() );
//   if(res.data.msg === 'success'){
//     toastr.success('Deleted Successfully')
//   }
  
// }catch (error) {
//   toastr.error("Oops", "Something went wrong.Please Try Again");
//  }
    
    
//   };

//   //** */ 
//   deleteAccountRef= async () => {
    
// try{
//   const res = await axios.post(`/api/DeleteAccountRef`, this.values() );
//   if(res.data.msg === 'success'){
//     toastr.success('Deleted Successfully')
//   }
  
// }catch (error) {
//   toastr.error("Oops", "Something went wrong.Please Try Again");
//  }
    
    
//   };
//@@@@
show = (val) => {

  this.setState({ open: true, module:val });
};

//******************* */
handleConfirm = async () => {
  
  // if(this.state.module === 'deleteNote'){
  //   this.deleteNote();
  // }
  // if(this.state.module === 'deleteContact'){
  //   this.deleteContact();
  // }
  if(this.state.module === 'deleteReceivablePayable'){
    this.deleteReceivablePayable();
  }
  if(this.state.module === 'deleteTask'){
    this.deleteTask();
  }
  
  // if(this.state.module === 'deleteLent'){
  //   this.deleteLent();
  // }
  // if(this.state.module === 'deleteBorrowed'){
  //   this.deleteBorrowed();
  // }
  // if(this.state.module === 'deleteItemLocation'){
  //   this.deleteItemLocation();
  // }
  // if(this.state.module === 'deleteFinance'){
  //   this.deleteFinance();
  // }
  // if(this.state.module === 'deleteAccountName'){
  //   this.deleteAccountName();
  // }
  // if(this.state.module === 'deleteAccountRef'){
  //   this.deleteAccountRef();
  // }  
//**********
this.setState({ result: "confirmed", open: false });
}

handleCancel = () => this.setState({ result: "cancelled", open: false });
//**********************************
  //@@@@
  url = ()=>{
    return this.props.parameter !== undefined &&
    this.props.parameter !== null &&
    this.props.parameter.allURL.length >0
        && this.props.parameter.allURL.map( (value) =><option key={value} value={value}>{value}</option>)
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  };

  renderSearchForm = ()=>
(  <Segment attached>
  <div>
    <Form onSubmit={this.onSubmit}>

  
      <div >

        <Form.Field >
          
          <label>Limit</label>
          <Input
            type="number"
            placeholder="limit"
            name="limit"
            value={this.state.limit}
            onChange={this.handleChange}
          />
        </Form.Field>

        <Form.Field >
        <label>URL</label>
        <select
          name="url"
          value={this.state.url}
          onChange={this.handleChange}
        >
          <option value=""></option>
          {this.url()}
          {/* {options()} */}
        </select>
        </Form.Field>
        {/* <Form.Field >
        <label>Lower Date</label>
        <Input
          type="date"
          placeholder="Date"
          name="dateL"
          value={this.state.dateL}
          onChange={this.handleChange}
        />
      </Form.Field> */}

      <Form.Field >
        <label>Higher Date</label>
        <Input
          type="date"
          placeholder="Date"
          name="dateH"
          value={this.state.dateH}
          onChange={this.handleChange}
        />
      </Form.Field>

      
      
      </div>
    </Form>

    </div>
  </Segment>

)


  render() {
    const { open,module  } = this.state;
    const con =`You Want To ${module} !`
    
    if (
      this.props.userA !== null &&
      (this.props.userA.financeRead === "Yes" ||this.props.userA.userType === "guest"||
        this.props.userA.userType === "admin")
    ) {
    return (
      <Container text>
      <Confirm
        header={con}
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />

        {/**/}
        <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Delete Entries
       {/*  */}
       </div>
       </div>
       {/**/}
       {this.renderSearchForm()}
       {/*  */}
       <Segment >
       {/* <Divider fitted />

        <div className="disflex-paginator" >
        
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteNote')}>
          Delete Note
        </Button>
        
        </div> */}
        {/*  */}
        {/* <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteContact') }>
          Delete Contact
        </Button>
      
        </div> */}
        {/*  */}
        <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteReceivablePayable') }>
          Delete Receivable Payable
        </Button>
      
        </div>
        {/*  */}
        <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteTask')  }>
          Delete Task
        </Button>
      
        </div>
        {/*  */}
        {/* <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteLent')  }>
          Delete Lent Item
        </Button>
      
        </div> */}
        {/*  */}
        {/* <Divider fitted />

 
        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteBorrowed') }>
          Delete Borrowed Item
        </Button>
      
        </div> */}
        {/*  */}
        {/* <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteItemLocation')  }>
          Delete Item Location
        </Button>
      
        </div> */}
        {/*  */}
        {/* <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteFinance') }>
          Delete Finance
        </Button>
      
        </div> */}
        {/*  */}
        {/* <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteAccountName') }>
          Delete AccountName
        </Button>
      
        </div> */}
        {/*  */}
        {/* <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show('deleteAccountRef') }>
          Delete EntryRefs
        </Button>
      
        </div> */}
        {/*  */}
        {/*  */}
        </Segment>
        {/*  */}
         
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View URL </h2>
        </div>
      </Segment>
    </Container>
  );
  }
  }



const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
    userA: state.auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {getUserForAuth,fetchAppParameter}
)(DeleteEntries);
