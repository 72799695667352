
import isEmpty from '../validation/is-empty';
import without from "lodash/without";
import {
  SET_CURRENT_USER,
  SEARCH_USERS,
  FETCH_USERS,
  FETCH_USER,
  FETCH_USERDETAIL,
 // UPDATE_USER,

 DESELECT_USER,
 SELECT_USER,
 RESET_SELECTION,

  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_BACKBUTTON ,
  HIDE_BACKBUTTON ,


} from '../actions/types';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: {},
  usersA: [],
  selections: [],
  userA: null,
  userD: null,
  offset: 0,
  limit: 20,
  visible:false,
  visibleBB:true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    //###################################################
    case FETCH_USERS:
      return { ...state, ...action.payload };

    case FETCH_USER:
      return { ...state, userA: action.payload };
      case FETCH_USERDETAIL:
        return { ...state, userD: action.payload };


    
    case SEARCH_USERS:

      return { ...state, ...action.payload };

      // **** select deselect
      case SELECT_USER:
    
      return { ...state, selections: [...state.selections, action.payload] };
    case DESELECT_USER:
      return {
        ...state,
        selections: without(state.selections, action.payload)
      };

    case RESET_SELECTION:
      
      return { ...state, selections: [] };
      
        //*********************SIDEBAR */
                case SHOW_SIDEBAR:
                return { ...state, visible: action.payload };
                case HIDE_SIDEBAR:
                return { ...state, visible: action.payload };
                //*********************backbutton */
                case SHOW_BACKBUTTON:
                return { ...state, visibleBB: action.payload };
                case HIDE_BACKBUTTON:
                return { ...state, visibleBB: action.payload };


    default:
      return state;
  }
};
