import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';


import AuthReducer from './AuthReducer';
import ModalsReducer from './ModalReducer';
import PopupsReducer from './PopupReducer';
import CommonReducer from './CommonReducer';
export default combineReducers({
  commons: CommonReducer,
  modals: ModalsReducer,
  popups: PopupsReducer,
  auth: AuthReducer,
  form: formReducer,
  
  toastr: toastrReducer
});
