// const cityO = ['Dwarka', 'Rohini', 'Gurugram'];
// export const city = () => {
//   return cityO.map(value => ({
//     key: value,
//     text: value,
//     value: value
//   }));
// };
//for dwarka only
const cityO = ['Dwarka'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const userTypeO = ['Individual', 'Shop Owner'];
export const userTypeOption = () => {
  return userTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
export const userType = () => {
  return userTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const residentTypeO = ['Owner', 'Tenant'];
export const residentTypeOption = () => {
  return residentTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const stateO = ['Delhi', 'Haryana', 'U P'];
export const state = () => {
  return stateO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const userStatusO = ['active', 'inactive'];
export const userStatus = () => {
  return userStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
export const userStatusOption = () => {
  return userStatusO.map(value => 
  <option 
  key={value } 
  value={value}
  >
    {value}
    </option>);
};

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18',
  'Sector-19',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
