import React from "react";
import { connect } from "react-redux";
import MessageModal from './MessageModal';
import LoginModal from './LoginModal';
import SearchCompanyiesModal from "./SearchCompanyiesModal";

import TermsAndConditionsModal from './TermsAndConditionsModal'
import CookiesPolicyModal from './CookiesPolicyModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'
// import UnauthModal from './UnauthModal'

const modalLookup = {
  MessageModal,
   LoginModal,
  SearchCompanyiesModal,

  CookiesPolicyModal,
  PrivacyPolicyModal,
  TermsAndConditionsModal,
  // UnauthModal
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
