import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import PrivacyPolicy from '../landing/PrivacyPolicy';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal }; 

class PrivacyPolicyModal extends Component {
  render() {
    return (
      <Modal size="small" open={true} onClose={this.props.closeModal}>
       
       <Modal.Content className='border'>
          <Modal.Description>
            <PrivacyPolicy />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(PrivacyPolicyModal);
