import React, { Component } from "react";
import includes from "lodash/includes";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import Paginator from "../paginator/Paginator";

import { searchUsers,deselectUser,selectUser,resetSelections} from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import CheckboxInput_2 from "../form/CheckboxInput_2"
import {
  Container,
  Divider,
  Segment,
  Icon,
  Button,
  Form,
  Image,
} from "semantic-ui-react";
import { userStatusOption } from "./Data";


class UserSearchList extends Component {
  state = {
    pageO:''
  };
  componentDidMount() {
    const {offset, limit} = this.props;

    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    this.props.searchUsers(values, limit,offset);

  }

  //**********************************
  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  //****************************************************
  handleSearchUser = () => {
    this.props.openModal("SearchCompanyiesModal");
  };
  
  handleClearSelections = () => {
    this.props.resetSelections();
  };

  onChange(_id) {
      if (includes(this.props.selections, _id)) {
        this.props.deselectUser(_id);
      } else {
        this.props.selectUser(_id);
      }
    }

    handleChange=(event)=> {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
  
      this.setState({
        [name]: value
      });
    }
    bulkEdit() {
      if (this.props.selections && this.props.selections.length > 0) {
        return (
          <Segment>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Field inline>
                  <label>User Status</label>
                  <select
                    name="userStatus"
                    value={this.state.asignTo}
                    onChange={this.handleChange}
                  >
                    <option value="">User Status</option>
                    {userStatusOption()}
                  </select>
                </Form.Field>
                
  
  {/*
                <Form.Button
                  floated="right"
                  //
                  color="teal"
                  type="submit"
                  //as={Link}
  
                  content="Submit"
                />
                <Form.Button
                  onClick={this.handleClearSelections}
                  inverted
                  color="blue"
                >
                  Clear Selections From List
                </Form.Button> */}
              </Form.Group>
              <div style={{textAlign:'center',marginTop:'5px'}}>
                <Button
              //inverted
              color="blue"
  
              //floated="right"
              type="submit">
                Submit
              </Button>
              <Button
              onClick={this.handleClearSelections}
              inverted
              color="blue"
              
            >
              Clear Selections From List
              </Button>
              </div>
            </Form>
          </Segment>
  
  
        );
      }
    }
    handleSubmit= async ()=> {
      const ids = this.props.selections;
      const value = this.state;
  
       const res = await axios.put(`/api/UserBulkupdate`, { ids, value });
    
        if(res.data.msg ==='success'){
          toastr.success('Updated Successfully')
          this.handleClearSelections()
          const {offset, limit} = this.props;

    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    this.props.searchUsers(values, limit,offset);
          
          this.props.resetSelections();
        //this.handleRefresh()
    }
    
    }
  
  
  back = () => {
    const {
      offset,
      limit

    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.searchUsers(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count

    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.searchUsers(values, limit, (offset + limit));
    window.scroll(0,0)
  };
  handleGoToPage = () => {
    const {

      limit,
      count

    } = this.props;

    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return;
      }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.searchUsers(values, limit, (page * limit));
      window.scroll(0,0)

  };

  //****************** */
  renderPaginator() {
    if (this.props.users.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
          //values={this.props.values}
        />
      );
    }
  }

  //^^^^^^^^^^^^^^^^^^^^^^^^^
  onChangeP=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
//*******************************************************
renderList=(user) => {
  const {
    //forSearch,
    uname,
    uimage,
    address,
    projectName,
    locality,
    city,
    state,
    pincode,
    userStatus,

    _id
  } = user;


//************************** */
const renderCheckbox = () =>
this.props.userA !== null &&
(this.props.userA.leadCreate === "Yes" ||
  this.props.userA.userType === "admin") ? (
   <span 
   > 
   
    <CheckboxInput_2 
    checked={includes(this.props.selections, _id)}
    onChange={() => this.onChange(_id)}
    />
    </span>
) : (
  <span></span>
);
 
  return (
    <Segment key={_id}>
        <div>
        <div >
          {/*  */}
        <div className='display-center'>
              <div>

              

                <span
                className="icon-button"
                >
                <Link to={`/user/UserDetail/${user._id}`} >
                <img className= 'yimg ' src='/detailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
                </Link>

                </span>
              

                </div>
              </div>
              {/**/}
        <div className="display-start color-blue font-20B ">{ renderCheckbox()}{uname}</div>
        

          </div>

          {/**/}
          <Divider fitted /> 
          <div >
          {uname && (
            <div className='row' style={{alignItems: 'center'}}>
              <div className='side'>
             
              <Image
  className="icon2-button "
  onClick={() => this.handleOpenModal(uimage)}
  style={{maxWidth:"50px", maxHeight:"50px",}}
    floated="left"
    size="tiny"
    src={`${uimage}`}
  />
              

              </div>
              <div className='main'>
              {/**/}
              {address && (
              <b
              className= {userStatus === 'inactive'? 'color-red':''} 
              >{address}{' '}{projectName}{' '} {locality} {' '}{city}{' '}{state}{' '}{pincode}</b>
              )}

              {/**/}
              </div>

            </div>
          )}
          </div>
          {/**/}
        </div>

    </Segment>
  );
}

  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {

    //******************
    if( this.props.userA !== null &&
      (this.props.userA.userType === "admin") ){

    return (
      <Container text>
      
        {/**/}
<div className='cashbook-H'>

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '}All Users
</div>
</div>

<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.handleSearchUser}>
    <span className='icon-button border1' > <Icon name='search' /></span >
  </div>


</div>
{/**/}
{this.bulkEdit()}
        {this.renderLoading()}
        {this.props.users.map(this.renderList)}


        {/*  */}
      
<div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator" >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChangeP}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      {/*  */}
<br/><br/><br/>

      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View All Users </h2>
        </div>
      </Segment>
    </Container>
  );
  }
}

const mapStateToProps = state => {

  return {
    limit: state.auth.limit,
    fetching: state.auth.fetching,
    offset: state.auth.offset,
    count: state.auth.count,
    users: state.auth.usersA,
    userA: state.auth.userA,
    user: state.auth.user,
    form: state.form.UserSearch,
    selections: state.auth.selections,
  };
};
export default connect(
  mapStateToProps,
  { openModal, searchUsers,deselectUser,selectUser,resetSelections}
)(UserSearchList);
